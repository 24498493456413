<template>
    <div class="class-wrapper">
        <div class="class-header" @click="closeDrawer">
            <span>{{className}}</span>
            <span>{{courseTitle}}</span>
            <span class="timer">
                上课中
                <span>{{getClassTime}}</span>
            </span>
            <el-button v-if="role === 3" type="primary" size="medium" round @click="endClass">结束上课</el-button>
            <div class="user">
                <div class="user-info">
                    <div class="user-avatar">
                        <img :src="userIcon ? userIcon : require('../../assets/images/user_img.png')" alt="" />
                    </div>
                    <el-dropdown class="header-el-dropdown" @command="logout">
                        <span class="user-text">
                            <span class="user-name text-overflow">{{username}}</span>
                            <i class="el-icon-arrow-down"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item icon="el-icon-switch-button" command="1">退出</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <div class="class-container">
            <div class="nav">
                <div class="nav-item" :class="{current: currentNav === 'chapter'}" @click="toggleNav('chapter')">
                    <i class="iconfont">&#xe62e;</i>
                    <span class="text">章节</span>
                </div>
                <div class="nav-item" :class="{current: currentNav === 'discuss'}" @click="toggleNav('discuss')">
                    <i class="iconfont">&#xe63b;</i>
                    <span class="text">讨论</span>
                </div>
                <div class="nav-item" :class="{current: currentNav === 'practical'}" @click="toggleNav('practical')">
                    <i class="iconfont">&#xe624;</i>
                    <span class="text">实训</span>
                </div>
                <div class="nav-item" :class="{current: currentNav === 'quiz'}" @click="toggleNav('quiz')">
                    <i class="iconfont">&#xe681;</i>
                    <span class="text">小测</span>
                </div>
                <div class="nav-item" v-if="role === 3" :class="{current: currentNav === 'signIn'}" @click="toggleNav('signIn')">
                    <i class="iconfont">&#xe636;</i>
                    <span class="text">签到</span>
                </div>
                <div class="nav-item" v-if="role === 3" :class="{current: currentNav === 'map'}" @click="toggleNav('map')">
                    <i class="iconfont">&#xe601;</i>
                    <span class="text">导图</span>
                </div>
                <div class="nav-item" v-if="role === 3" :class="{current: currentNav === 'blackBoard'}" @click="toggleNav('blackBoard')">
                    <i class="iconfont">&#xe66a;</i>
                    <span class="text">黑板</span>
                </div>
            </div>
            <div class="container">
                <div class="video-box" v-if="videoSrc">
                    <video :src="videoSrc" controls="controls" autoplay controlslist="nodownload" ref="videoRef"></video>
                </div>
                <div class="iframe-content" v-if="pdfSrc">
                    <!--右侧工具栏-->
                    <WareToolInClass :resource_id="resource_id" :course_name="courseTitle" :zhangIndex="chapterIndex" :jieIndex="nodeIndex" v-if="role === 3"></WareToolInClass>
                    <iframe :src="pdfSrc" frameborder="0" width="100%" height="100%"></iframe>
                </div>
                <div class="ep-tmpl" v-if="examPaper.paperName">
                    <el-scrollbar class="paper-wrapper" style="height: 100%" :native="false">
                        <div class="paper-container">
                            <div class="paper-title">{{examPaper.paperName}}</div>
                            <div class="paper-content">
                                <exam-paper-template :data.sync="examPaper.paperContent"></exam-paper-template>
                            </div>
                        </div>
                    </el-scrollbar>
                </div>
                <div class="ep-tmpl" v-if="project.id">
                    <el-scrollbar class="paper-wrapper" style="height: 100%" :native="false">
                        <div class="paper-container">
<!--                            <TeacherCourseProjectDetail :projectId.sync="project.projectId" />-->
                            <div style="width: 1200px; margin: 20px auto;">
                                <project-info :project="project" :tabs="[]" :app_lists="app_lists"></project-info>
                            </div>
                        </div>
                    </el-scrollbar>
                </div>
            </div>
        </div>
<!--        @close="closeDrawer"-->
        <el-drawer :visible="currentNav === 'chapter'" direction="ltr" size="405px" :modal="false" :with-header="false" class="chapter-drawer" :before-close="closeDrawer">
            <div class="chapterNode-cascader-wrapper" v-clickoutside="hideChapterNode" v-if="role === 3">
                <el-input :value="chapterNodeCurrentText" readonly @click.native="openChapterNode" placeholder="请选择章节"></el-input>
                <div class="box" v-show="showChapterNode">
                    <el-cascader-panel ref="chapterCascader" v-model="chapterNodeCurrent" popper-class="chapterNode-cascader" class="chapterNode-cascaderBox"
                                       :options="chapterNodeList" :props="keyObj" @change="chooseChapterNode"></el-cascader-panel>
                </div>
            </div>
            <Chapter @getResource="getResource" :chapterList="chapterList" :chapterNodeCurrent="chapterNodeCurrentSave" :currentCourseRecordIndex="current_course_record_index"></Chapter>
        </el-drawer>
        <el-drawer :visible="currentNav === 'discuss'" direction="rtl" size="405px" :modal="false" title="讨论" class="discuss-drawer" :before-close="closeDrawer">
            <Discuss ref="discussComponent" :data="chatList" @sendMesg="sendMessage"></Discuss>
        </el-drawer>
        <el-drawer :visible="currentNav === 'practical'" direction="rtl" size="405px" :modal="false" :with-header="false" class="practical-drawer" :before-close="closeDrawer">
            <Practical ref="practicalComponent" @sendPra="sendPractical" :chapterNodeCurrent="chapterNodeCurrentSave"></Practical>
        </el-drawer>
        <el-drawer :visible="currentNav === 'quiz'" direction="rtl" size="405px" :modal="false" title="小测" class="quiz-drawer" :before-close="closeDrawer">
            <Quiz ref="quizComponent" @sendMsg="sendMsgTest" :chapterNodeCurrent="chapterNodeCurrentSave"></Quiz>
        </el-drawer>
        <el-dialog title="签到" :visible="currentNav === 'signIn'" width="600px" @close="closeDrawer" class="signDialog">
            <div class="sign-content">
                <div class="sign-title">已签到人数/总人数</div>
                <div class="sign-num">{{signPercent}}</div>
                <el-table :data="signList" border style="width: 100%; margin-top: 10px;" height="300px"  size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa', textAlign: 'center'}" :cell-style="{fontSize: '12px',color: '#333', textAlign: 'center'}">
                    <el-table-column prop="s_name" label="学生姓名"></el-table-column>
                    <el-table-column label="签到状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status === 0">未签到</span>
                            <span v-else>已签到</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="begin_time" label="签到时间"></el-table-column>
                    <el-table-column prop="sign_ip" label="签到IP"></el-table-column>
                </el-table>
                <el-pagination class="pages-center"
                               :current-page.sync="signPages.currentPageNum"
                               :page-size="signPages.eachPageNum"
                               :total="signPages.total"
                               layout="prev, pager, next, jumper"
                               @current-change="signCurrentChange">
                </el-pagination>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" type="primary" @click="closeDrawer">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="思维脑图" :visible="currentNav === 'map'" width="100%" :fullscreen="true" class="blackboard-dialog" @close="closeDrawer">
            <iframe :src="mindMapSrc" frameborder="0" width="100%" height="100%"></iframe>
        </el-dialog>
        <el-dialog title="课堂黑板" :visible="currentNav === 'blackBoard'" width="100%" style="height: 100%;" :fullscreen="true" class="blackboard-dialog" @close="closeDrawer">
            <iframe :src="blackBoardSrc" frameborder="0" width="100%" height="100%"></iframe>
        </el-dialog>
        <el-dialog title="提示消息" :visible.sync="endClassDialog" width="30%" :show-close="false" :close-on-click-modal="false">
            <div style="text-align: center; font-size: 16px">该教师已结束上课！</div>
            <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="sureEndClass">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
    import wsc from "../../utils/wsConfig";
    import Chapter from "../../components/classIn/Chapter";
    import Practical from "../../components/classIn/Practical";
    import Quiz from "../../components/classIn/Quiz";
    import Discuss from "../../components/classIn/Discuss";
    import examPaperTemplate from "../../components/material/examPaper/examPaperTemplate";
    import { mapGetters } from 'vuex';
    import _ from 'underscore';
    import TeacherCourseProjectDetail from '../../components/teacherCourse/TeacherCourseProjectDetail'
    import clickoutside from '../../utils/clickoutside'
    import WareToolInClass from "../../components/classIn/WareToolInClass";
    import ProjectInfo from "@/components/material/ProjectInfo";
    import {sendWsData} from "@/utils/utils";
    export default {
        directives: {
            clickoutside
        },
        components: {
            Quiz,
            Chapter,
            Practical,
            Discuss,
            examPaperTemplate,
            TeacherCourseProjectDetail,
            WareToolInClass,
            'project-info': ProjectInfo
        },
        data() {
            return {
                current_course_record_index:"",
                resource_id: '',
                id: Number(this.$route.query.id) || '',
                classId: Number(this.$route.query.classId) || '',
                recordId: Number(this.$route.query.recordId) || '',
                className: this.$route.query.className || '',
                chapterIndex: this.$route.query.chapter || '',
                nodeIndex: this.$route.query.node || '',
                role: Number(localStorage.getItem('role')),
                username: localStorage.getItem('sUsername'),
                teacher_id: Number(localStorage.getItem('sId')) || '',
                student_id: Number(localStorage.getItem('s_id')) || '',
                send_id: 0,
                userIcon:  '',
                courseTitle: '',
                currentNav: '',
                videoSrc: '',
                pdfSrc: '',
                chapterList: [],
                indexCurrent: '',
                signPercent: '',
                mindMapSrc: '',
                mindMapUrl: '',
                blackBoardSrc: '',
                blackBbUrl: '',
                signList: [],
                signPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                classTime: 0,
                examPaper: {
                    paperName: '',
                    paperContent: {}
                },
                ws: null,
                endClassDialog: false,
                chatList:[],
                endIndex: '',

                // cascaderKey: 1,
                keyObj: {
                    lazy: true,
                    lazyLoad: this.lazyLoad,
                    checkStrictly: true,
                    label: 'label',
                    value: 'value',
                    children: 'children',
                },
                chapterNodeCurrentText: '',
                showChapterNode: false,
                chapterNodeCurrent: [],
                chapterNodeCurrentSave: [],
                chapterNodeList: [],
                project: {
                    project_title: '',
                    project_ability: [],
                    project_attrs: [],
                    project_evaluate: [],
                    project_knowledge_link: [],
                    project_sucai: [],
                    project_task: [],
                    project_duration: 0,
                    project_difficulty: 0
                },
                alreadyAssignCourse: [],
                app_lists: []
            }
        },
        created() {
            // console.log('测试开发',process.env);
            this.getDiscussList();
            if (this.role === 3) {
                this.getAlreadyAssign()
            }
            // if (this.role === 4) {
            //     this.getChapterNodeList()
            // }
        },
        mounted() {
            if (this.role === 3) {
                // this.getChapterNodeResourceList(this.id, this.chapterIndex)
                this.getCourseCustomList(this.id, this.chapterIndex, this.nodeIndex);
                this.chapterNodeCurrentSave = [this.$route.query.chapter, this.$route.query.node]
            }
            if (this.role === 4) {
                this.getCourseList();
                this.getStudentCourseList()
            }
            let userIcon = localStorage.getItem('sIcon');
            if (userIcon !== 'null') {
                this.userIcon = userIcon;
            }

            this.mindMapUrl = this.$api.mindMapUrl;
            this.blackBbUrl = this.$api.blackBbUrl;
            if (this.getRefreshStatus === 1) {
                this.classTime = localStorage.getItem('classTime');
                setInterval(() => {
                    localStorage.setItem('classTime', this.classTime++)
                    // this.classTime++;
                }, 1000);
            } else {
                setInterval(() => {
                    localStorage.setItem('classTime', this.classTime++)
                }, 1000);
            }
            // 实时
            if (this.role === 3) {
                this.send_id = this.teacher_id;
            } else if (this.role === 4) {
                this.send_id = this.student_id;
            }
            this.websocketInit()
        },
        computed: {
            getClassTime() {
                if (this.classTime < 60) {
                    if (this.classTime < 10) {
                        return `00:0${this.classTime}`
                    }
                    else {
                        return `00:${this.classTime}`
                    }
                } else {
                    let minute = parseInt(this.classTime / 60);
                    let second = this.classTime % 60;
                    if (minute < 60) {
                        if (minute < 10) {
                            if (second < 10) {
                                return `0${minute}:0${second}`
                            } else {
                                return `0${minute}:${second}`
                            }
                        } else {
                            if (second < 10) {
                                return `${minute}:0${second}`
                            } else {
                                return `${minute}:${second}`
                            }
                        }
                    } else {
                        let hours = parseInt(this.classTime / 3600);
                        let minuteH = parseInt(this.classTime % 3600 / 60);
                        let secondH = this.classTime % 3600 % 60 % 60;
                        if (hours < 10) {
                            if (minuteH < 10) {
                                if (secondH < 10) {
                                    return `0${hours}:0${minuteH}:0${secondH}`
                                } else {
                                    return `0${hours}:0${minuteH}:${secondH}`
                                }
                            } else {
                                if (secondH < 10) {
                                    return `0${hours}:${minuteH}:0${secondH}`
                                } else {
                                    return `0${hours}:${minuteH}:${secondH}`
                                }
                            }
                        } else {
                            return `${hours}:${minuteH}:${secondH}`
                        }
                    }
                }
            },
            ...mapGetters(['getRefreshStatus'])
        },
        watch: {
            $route: (newRoute) => {
                this.id = newRoute.query.id
            },
            chapterList(val) {
                let chapterIndex = this.chapterList.length - 1;
                if (chapterIndex !== undefined) {
                    this.endIndex = chapterIndex;
                }
                let lastOne = this.chapterList[this.chapterList.length - 1];
                if (lastOne.children.length) {
                    let sectionIndex = lastOne.children.length - 1;
                    if (sectionIndex !== undefined){
                        this.endIndex = this.endIndex + '|' + sectionIndex;
                    }
                    let sectionLast = lastOne.children[lastOne.children.length - 1];
                    if (Number(sectionLast.type) === 2 && sectionLast.children.length) {
                        let resourceIndex = sectionLast.children.length - 1;
                        if (resourceIndex !== undefined) {
                            this.endIndex = this.endIndex + '|' + resourceIndex;
                        }
                    }
                }
            }
        },
        methods: {
            websocketInit() {
                this.ws = new WebSocket(wsc.url)
                this.ws.onopen = () => {
                    console.log("连接成功🏅️")
                    const role = localStorage.getItem("role")
                    let sendMsg = ""
                    if (Number(role) === 3) {
                        sendMsg = sendWsData("bind", {c_id: this.$route.query.classId})
                    } else {
                        sendMsg = sendWsData("bind")
                    }
                    this.ws.send(sendMsg)
                }
                this.ws.onclose = () => {
                    console.log("连接断开,正在重新连接")
                    this.websocketInit()
                }
                //接受消息
                this.ws.onmessage = (event) => {
                    try {
                        const msg = JSON.parse(event.data)
                        // console.log("msg", msg)
                        //更新讨论列表
                        if (msg.code === 200 && msg.data.type === "msg") {
                            this.chatList.push(msg.data.data)
                            this.currentNav = "discuss"
                            if (this.currentNav === "discuss") {
                                this.$nextTick(() => {
                                    this.$refs.discussComponent && this.$refs.discussComponent.toBottom()
                                })
                            }
                        }
                        //更新项目列表
                        if (msg.code === 200 && msg.data.type === "project_notice") {
                            this.currentNav = "practical"
                            this.$refs.practicalComponent && this.$refs.practicalComponent.getPracticalList()
                        }

                        //更新小测列表
                        if (msg.code === 200 && msg.data.type === "test_notice") {
                            this.currentNav = "quiz"
                            this.$refs.quizComponent && this.$refs.quizComponent.getQuizList()
                        }
                        //教师上课结束通知
                        if (msg.code === 200 && msg.data.type === "end_notice") {
                            this.endClassDialog = true
                        }
                    }catch (e) {
                        console.log("e", e)
                    }
                }
            },
            stopClassNotice() {
                const send_data = sendWsData("end_notice", {c_id: this.$route.query.classId})
                this.ws.send(send_data)
            },
            //发送小测消息
            sendMsgTest(val) {
                const content = {
                    c_id: this.$route.query.classId
                }
                this.ws.send(sendWsData("test_notice", content))
            },
            //发送讨论消息
            sendMessage(val) {
                const content = {
                    ...val,
                    c_id: this.$route.query.classId
                }
                const data = sendWsData("message", content)
                this.ws.send(data)
            },
            //发布实训
            sendPractical(val) {
                const msg = sendWsData("project_notice", {
                    c_id: this.$route.query.classId,
                })
                this.ws.send(msg);
            },
            // 打开章节二级
            openChapterNode() {
                this.showChapterNode = true
            },
            // 点击空白地方关闭弹窗
            hideChapterNode() {
                this.showChapterNode = false
            },
            // 教师端获取已分配课程
            getAlreadyAssign() {
                this.$shttp.axiosGet(this.$api.admin_showTeaCourse, (res) => {
                    if (res.code === 200) {
                        let alreadyList = JSON.parse(res.data.course_data)
                        let newAlreadyList = []
                        alreadyList.forEach(item => {
                            let includeIndex = newAlreadyList.findIndex(item1 => {
                                return String(item.course_id) === item1.course_id
                            })
                            if (includeIndex === -1) {
                                let alreadyItem = {
                                    course_id: String(item.course_id),
                                    children: [String(item.zhang_arr)]
                                }
                                newAlreadyList.push(alreadyItem)
                            } else {
                                newAlreadyList[includeIndex].children.push(String(item.zhang_arr))
                            }
                        })
                        let newAlreadyListEnd = []
                        newAlreadyList.forEach((item, index) => {
                            if (item.course_id === String(this.id)) {
                                newAlreadyListEnd.push(item)
                            }
                        })
                        this.alreadyAssignCourse = newAlreadyListEnd
                        this.getChapterNodeList();
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //遍历讨论的历史聊天记录
            getDiscussList() {
                let param = {
                    course_record_id: Number(this.$route.query.recordId) || '',
                    c_id: Number(this.$route.query.classId) || '',
                    type: 'all'
                }
                this.$shttp.axiosGetBy(this.$api.showDiscussNums, param, (res) => {
                    if (res.code === 200) {
                        this.chatList = res.data;
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 章节筛选内 懒加载
            lazyLoad(node, resolve) {
                this.$refs.chapterCascader.clearCheckedNodes()
                const { level } = node;
                if (level === 2) {
                    resolve()
                }
                if (level === 1) {
                    if (node.data.disabled) {
                        this.$message.warning('该章节未分配')
                    }
                    this.$shttp.axiosGetBy(this.$api.courseContent, {id: this.id, chapter: node.value}, (res2) => {
                        if (res2.code === 200) {
                            let nodeList = res2.data.course_content.children
                            let nodeTmp = []
                            nodeList.forEach((item2, index2) => {
                                let nodeItemTmp = {
                                    value: String(index2),
                                    label: item2.name,
                                    leaf: 'leaf',
                                    disabled: node.data.disabled
                                }
                                nodeTmp.push(nodeItemTmp)
                            })
                            resolve(nodeTmp)
                        } else {
                            this.$message.warning(res2.msg)
                        }
                    })
                }
            },
            sureEndClass() {
                this.$router.push('/myclass/detail')
            },
            // 学生端获取左侧章节列表
            getCourseList() {
                let params = {
                    id: this.id,
                    course_record_id: Number(this.$route.query.recordId) || '',
                }
                this.$shttp.axiosGetBy(this.$api.courseContent, params, (res) => {
                    if (res.code === 200) {
                        this.chapterList = this.$common.filterResource(res.data.course_content, res.data.reslist, res.data.papers, res.data.projects);
                        this.courseTitle = res.data.course_name;
                        this.current_course_record_index=res.data.current_course_record_index
                        let sectionSt = this.chapterList[0].children[0];
                        let current_section_item=sectionSt;
                        if (Number(sectionSt.type) === 2) {
                            let piece = sectionSt.children[0];
                            this.indexCurrent = '0|0|0';
                            current_section_item=piece
                          //  this.getResource(piece, this.indexCurrent);
                        } else {
                            this.indexCurrent = '0|0';
                          //  this.getResource(sectionSt, this.indexCurrent);
                        }

                        if(this.current_course_record_index!==''){
                          let current_course_index_ary=this.current_course_record_index.split("|")
                          if(current_course_index_ary && current_course_index_ary.length>=2){
                            let chap_index=Number(current_course_index_ary[0])
                            if(this.chapterList.length>=(chap_index+1)){
                              let children=this.chapterList[chap_index]['children'];
                              let section_index=Number(current_course_index_ary[1])
                              if(children.length>=(section_index+1)){
                                this.indexCurrent = this.current_course_record_index
                                sectionSt=children[section_index]
                                current_section_item=sectionSt
                                if(current_course_index_ary.length===3 && Number(sectionSt.type) === 2){
                                  let piece_index=Number(current_course_index_ary[2])
                                  if(sectionSt['children'].length>=(piece_index+1)){
                                    current_section_item=sectionSt['children'][piece_index];
                                  }

                                }
                              }
                            }
                          }
                        }

                      this.getResource(current_section_item, this.indexCurrent);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            getStudentCourseList() {

            },
            // 教师端 获取当前课程的章节 二级弹窗列表
            getChapterNodeList() {
                if (this.$route.query.id !== '') {
                    // ++this.cascaderKey
                    if (this.role === 3) {
                        this.$shttp.axiosGetBy(this.$api.courseContent, {id: this.id}, (res1) => {
                            if (res1.code === 200) {
                                if (res1.data.course_content.length > 0) {
                                    let chapterList = res1.data.course_content;
                                    let chapterTmp = []
                                    if (this.id === res1.data.course_id) {
                                        chapterList.forEach((item1, index1) => {
                                            let chapterItemTmp = {
                                                value: String(index1),
                                                label: item1.name,
                                                children: [],
                                                disabled: true,
                                            }
                                            chapterTmp.push(chapterItemTmp)
                                        })
                                        if (chapterTmp.length > 0) {
                                            chapterTmp.forEach(item => {
                                                if (this.alreadyAssignCourse.length > 0) {
                                                    let currentChapter = this.alreadyAssignCourse[0].children.find(item1 => {
                                                        return item.value === String(item1)
                                                    })
                                                    if (currentChapter) {
                                                        item.disabled = false
                                                    }
                                                }
                                            })
                                        }
                                    }
                                    this.chapterNodeList = chapterTmp
                                }
                            } else {
                                this.$message.warning(res1.msg)
                            }
                        }, (err) => {
                            console.log(err)
                        })
                    }
                    // 设置默认显示章的节
                    // this.$shttp.axiosGetBy(this.$api.courseContent, {id: this.id, chapter: this.chapterIndex}, (res2) => {
                    //     if (res2.code === 200) {
                    //         if (res2.data.course_content.children.length > 0) {
                    //             let nodeList = res2.data.course_content.children
                    //             let nodeTmp = []
                    //             if (this.chapterNodeList.length > 0) {
                    //                 let currentChapterIndex = this.chapterNodeList.findIndex(item => {
                    //                     return item.value === this.chapterIndex
                    //                 })
                    //                 if (currentChapterIndex !== -1) {
                    //                     nodeList.forEach((item, index) => {
                    //                         let nodeItemTmp = {
                    //                             value: String(index),
                    //                             label: item.name,
                    //                             leaf: 'leaf'
                    //                         }
                    //                         nodeTmp.push(nodeItemTmp)
                    //                     })
                    //                     this.chapterNodeList[currentChapterIndex].children = nodeTmp
                    //                 }
                    //             }
                    //         }
                    //         console.log('chapterNodeList', this.chapterNodeList)
                    //     } else {
                    //         this.$message.warning(res2.msg)
                    //     }
                    // }, (err) => {
                    //     console.log(err)
                    // })
                }
            },
            // 教师端 筛选章节
            chooseChapterNode(val) {
                if (val.length === 0) {
                    return
                } else {
                    this.chapterNodeCurrentSave = val
                }
                this.getCourseCustomList(this.id, val[0], val[1])
                // this.getChapterNodeResourceList(this.id, val[0])
            },
            //教师端 查询老师备课的资源
            getCourseCustomList(courseId, chapterIndex, nodeIndex) {
                let format_index = {};
                let param = {
                    course_id: String(courseId),
                    course_chapter: String(chapterIndex),
                    course_node: String(nodeIndex),
                }
                this.$shttp.axiosGetBy(this.$api.course_getcoursecustom, param, (res) => {
                    if (res.code === 200) {
                        if (res.data.length === 0) {
                            this.getChapterNodeResourceList(courseId, chapterIndex)
                        } else {
                            format_index = Object.assign(format_index, {
                                chapter_key: res.data.course_chapter,
                                node_key: res.data.course_node,
                                res_key: res.data.course_content
                            });
                            // 教师端 获取左侧章节资源列表
                            let params = {
                                id: courseId,
                                chapter: chapterIndex,
                            }
                            this.$shttp.axiosGetBy(this.$api.courseContent, params, (res) => {
                                if (res.code === 200) {
                                    let chapterNodeList = res.data.course_content
                                    let chapterNodeTmp = []
                                    let chapterNodeItemTmp = {
                                        type: chapterNodeList.type,
                                        name: chapterNodeList.name,
                                        children: chapterNodeList.children,
                                    }
                                    let reslist = res.data.reslist;
                                    let exams = res.data.papers;
                                    let projects = res.data.projects;
                                    chapterNodeTmp.push(chapterNodeItemTmp)

                                    this.chapterList = this.$common.filterResource(chapterNodeTmp, reslist, exams, projects, format_index);
                                    // console.log('章节',this.chapterList);
                                    // 设置输入框内默认显示的章节
                                    let chapterName = this.chapterList[0].name
                                    let nodeName = this.chapterList[0].children[this.chapterNodeCurrentSave[1]].name
                                    this.chapterNodeCurrentText = chapterName + ' / ' + nodeName

                                    this.courseTitle = res.data.course_name;
                                    let sectionSt = this.chapterList[0].children[0];
                                    if (Number(sectionSt.type) === 2) {
                                        let piece = sectionSt.children[0];
                                        this.indexCurrent = '0|0|0';
                                        this.getResource(piece, this.indexCurrent);
                                    } else {
                                        this.indexCurrent = '0|0';
                                        this.getResource(sectionSt, this.indexCurrent);
                                    }
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        }
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            // 教师端 获取左侧章节资源列表
            getChapterNodeResourceList(courseId, chapterIndex) {
                let params = {
                    id: courseId,
                    chapter: chapterIndex
                }
                this.$shttp.axiosGetBy(this.$api.courseContent, params, (res) => {
                    if (res.code === 200) {
                        let chapterNodeList = res.data.course_content
                        let chapterNodeTmp = []
                        let chapterNodeItemTmp = {
                            type: chapterNodeList.type,
                            name: chapterNodeList.name,
                            children: chapterNodeList.children,
                        }
                        chapterNodeTmp.push(chapterNodeItemTmp)
                        this.chapterList = this.$common.filterResource(chapterNodeTmp, res.data.reslist, res.data.papers, res.data.projects);

                        // 设置输入框内默认显示的章节
                        let chapterName = this.chapterList[0].name
                        let nodeName = this.chapterList[0].children[this.chapterNodeCurrentSave[1]].name
                        this.chapterNodeCurrentText = chapterName + ' / ' + nodeName

                        this.courseTitle = res.data.course_name;
                        let sectionSt = this.chapterList[0].children[0];
                        if (Number(sectionSt.type) === 2) {
                            let piece = sectionSt.children[0];
                            this.indexCurrent = '0|0|0';
                            this.getResource(piece, this.indexCurrent);
                        } else {
                            this.indexCurrent = '0|0';
                            this.getResource(sectionSt, this.indexCurrent);
                        }
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            logout(command) {
                this.$shttp.axiosGet(this.$api.logout, (res) => {
                    if (res.code === 200) {
                        this.stopClassNotice()
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: res.msg,
                            onClose: ()=> {
                                localStorage.removeItem('sToken');
                                localStorage.removeItem('sUsername');
                                localStorage.removeItem('role');
                                if (this.$route.path === '/'){
                                    window.location.reload();
                                } else {
                                    this.$router.push('/');
                                }
                            }
                        })
                    } else {
                        this.$message({
                            type: 'warning',
                            duration: 1000,
                            message: res.msg,
                            onClose: ()=> {
                                localStorage.removeItem('sToken');
                                localStorage.removeItem('sUsername');
                                localStorage.removeItem('role');
                                if (this.$route.path === '/'){
                                    window.location.reload();
                                } else {
                                    this.$router.push('/');
                                }
                            }
                        })
                    }
                })
            },
            endClass() {
                this.$confirm('确定结束上课吗？', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let params = {
                        course_record_id: this.recordId,
                        chapter_sign: this.indexCurrent.split('|')[0],
                    }
                    if (this.indexCurrent === this.endIndex) {
                        params.is_end = 1
                    }
                    this.$shttp.axiosPost(this.$api.endingCourse, params, (res) => {
                        if (res.code === 200) {
                            this.stopClassNotice()
                            this.$message.success(res.msg);
                            this.$router.push('/myclass')
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消结束上课！')
                });
            },
            toggleNav(item) {
                this.currentNav === item ? this.currentNav = '' : this.currentNav = item;
                if (item === "discuss") {
                    this.getDiscussList()
                }
                if (this.role === 3) {
                    this.getSignPercent();
                }
                this.getSignList();
                if (item === 'practical') {
                    this.$refs.practicalComponent.getPracticalList()
                    setTimeout(() => {
                        this.$refs.practicalComponent.getLessonPreparationList();

                    }, 10);
                }
                if (item === 'quiz') {
                    setTimeout(() => {
                        this.$refs.quizComponent.getLessonQuizList();
                    }, 10);
                }
                if (item === 'map') {
                    this.mindMapSrc = this.mindMapUrl;
                }
                if (item === 'blackBoard') {
                    this.blackBoardSrc = this.blackBbUrl;
                }
                if (item === 'chapter') {
                    // 设置默认显示的章节，会导致数据出现重复
                    // this.chapterNodeCurrent = [this.$route.query.chapter, this.$route.query.node]
                }
            },
            closeDrawer() {
                if (this.currentNav === 'chapter'
                        || this.currentNav === 'discuss' || this.currentNav === 'practical'
                            || this.currentNav === 'quiz' || this.currentNav === 'signIn'
                                || this.currentNav === 'map' || this.currentNav === 'blackBoard') {
                    this.currentNav = '';
                }
            },
            getResource(item, index) {
                this.pdfSrc =  '';
                this.videoSrc =  '';
                this.examPaper.paperName = '';
                this.indexCurrent = index;
                // this.indexCurrent = index;
                let type = parseInt(item.type);
                let _id = item.resource_id ? Number(item.resource_id) : item.id;
                switch (type) {
                    case 3:
                        if (item.resource_file_type && item.resource_file_type.toLowerCase() === 'mp4') {
                            if(typeof item.file_preview_path === "object"){
                                this.videoSrc = item.file_preview_path.url;
                            } else {
                                this.videoSrc = item.file_preview_path;
                            }
                          this.$nextTick(() => {
                            const videoDom = this.$refs.videoRef
                            videoDom.oncontextmenu = function () {
                              return false
                            }
                          })
                        } else {
                            let pdfUrl = this.$api.pdfPreview + _id;
                            this.pdfSrc = process.env.NODE_ENV === 'development' ? this.$config.baseUrl : '';
                            this.pdfSrc +=  pdfUrl;
                        }
                        this.resource_id = _id;
                        break;
                    case 4:
                        this.$shttp.axiosGetBy(this.$api.findTestPaper, { id: item.id }, (res) => {
                            if (res.code === 200) {
                                this.$set(this.examPaper, 'paperName', res.data.paper_name);
                                let paper_content = JSON.parse(res.data.paper_content);
                                for(let key in paper_content) {
                                    if (paper_content[key].length) {
                                        if (key === 'multiple') {
                                            _.map(paper_content[key], (item) => {
                                                item.checkOptions = [];
                                            });
                                        }
                                        this.$set(this.examPaper.paperContent, `${key}`, paper_content[key]);
                                    }
                                }
                            }
                        });
                        break;
                    case 5:
                        this.$shttp.axiosGetBy(this.$api.getProjectDetail, {id: item.id}, (res) => {
                            if (res.code === 200)
                            {
                                console.log(res.data);
                                this.project = Object.assign({}, res.data);
                                this.app_lists = res.data.app_lists;
                            }
                        }, (err) => {
                            this.$common.axiosErrorMsg(err);
                        })
                }
            },
            getSignPercent() {
                let params = {
                    c_id: this.classId,
                    course_record_id: this.recordId,
                }
                this.$shttp.axiosGetBy(this.$api.showClassSignIn, params, (res) => {
                    if (res.code === 200) {
                        this.signPercent = res.data.percent
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            getSignList() {
                let params = {
                    course_record_id: this.recordId,
                    page: this.signPages.currentPageNum,
                    limit: this.signPages.eachPageNum,
                }
                this.$shttp.axiosGetBy(this.$api.showSignInList, params, (res) => {
                    if (res.code === 200) {
                        this.signList = res.data.data
                        this.signPages.total = res.data.total
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            signCurrentChange(val) {
                this.signPages.currentPageNum = val;
                this.getSignList()
            },
        }
    }
</script>

<style scoped lang="scss">
    .ep-tmpl {
        width: 100%;
        height: 100%;
        background-color: #FFF;
        .paper-wrapper {
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
        .paper-container {
            background-color: #FFF;
            width: 100%;
            .paper-title {
                width: 100%;
                text-align: center;
                font-size: 24px;
                color: #409eff;
                font-weight: 600;
            }
            .paper-content {
                margin-top: 20px;
                padding: 0 20px;
            }
        }
    }
    .class-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        .class-header {
            background: #fff;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 16px;
            position: relative;
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2);
            /*z-index: 20000;*/
        }
        .class-container {
            height: 1%;
            flex: 1;
            display: flex;
            .nav {
                width: 60px;
                color: #B3B3B3;
                background: #1C1F21;
                display: flex;
                justify-content: center;
                flex-direction: column;
                .nav-item {
                    cursor: pointer;
                    width: 60px;
                    height: 60px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .iconfont {
                        font-size: 16px;
                    }
                    .text {
                        margin-top: 2px;
                    }
                    &.current {
                        color: #fff;
                        background: rgb(38, 41, 44);
                    }
                }
            }
            .container {
                width: 1%;
                flex: 1;
                overflow: hidden;
                background-color: #FFF;
                .video-box {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #000;
                    video {
                        max-width: 100%;
                        max-height: 100%;
                        outline: none;
                    }
                }
                .iframe-content {
                    height: 100%;
                }
            }
        }
    }
    .class-header {
        & > span {
            margin-right: 10px;
            color: #1989FA;
        }
        .timer {
            color: #FF0000;
            margin: 0 50px 0 20px;
            span {
                color: #FF0000;
                margin-left: 20px;
            }
        }
        .user {
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%);
            .user-info {
                display: flex;
                align-items: center;
                .user-name {
                    font-size: 16px;
                    color: #606266;
                    margin-right: 20px;
                }
                .el-icon-arrow-down {
                    color: #606266;
                }
            }
            .user-avatar {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                overflow: hidden;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                border: 1px solid #ddd;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .header-el-dropdown {
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 10px 0;
                .user-text {
                    line-height: 40px;
                    margin-left: 10px;
                }
                img {
                    display: block;
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                }
            }
        }
    }
    .chapter-drawer {
        position: fixed;
        left: 60px;
        top: 60px;
        ::v-deep .el-drawer {
            background: #26292C;
            outline: none;
        }
        ::v-deep .el-drawer__body {
            outline: none;
            height: 100%;
        }
    }
    .practical-drawer {
        position: fixed;
        top: 60px;
        left: 60px;
        ::v-deep .el-drawer {
            height: 100%;
            border-top: 1px solid #EAEAEA;
            padding: 15px 0 0;
            &:focus {
                outline: none;
            }
            .el-drawer__body {
                height: 100%;
                .practical-content {
                    height: 100%;
                }
                .el-tabs {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    .el-tabs__nav {
                        padding: 0 28px;
                        .el-tabs__active-bar {
                            left: 28px;
                        }
                    }
                    .el-tabs__content {
                        flex: 1;
                    }
                }
            }
        }
    }
    .discuss-drawer {
        position: fixed;
        top: 60px;
        left: 60px;
        ::v-deep .el-drawer {
            height: 100%;
            border-top: 1px solid #EAEAEA;
            outline: none;
            &:focus {
                outline: none;
            }
            .el-drawer__header span {
                outline: none;
            }
            .el-drawer__body {
                height: calc(100% - 76px);
                .practical-content {
                    height: 100%;
                }
            }
        }
    }
    .quiz-drawer {
        position: fixed;
        top: 60px;
        left: 60px;
        ::v-deep .el-drawer {
            border-top: 1px solid #EAEAEA;
            outline: none;
            background: #F5F6FA;
        }
        ::v-deep .el-drawer__header {
            margin-bottom: 0;
            span, button {
                outline: none;
            }
        }
        ::v-deep .el-drawer__body {
            outline: none;
            height: calc(100% - 45px);
        }
    }
    .signDialog {
        ::v-deep .el-dialog__body {
            border-top: 1px solid #EAEAEA;
            border-bottom: 1px solid #EAEAEA;
            padding: 20px 0;
        }
        ::v-deep .el-dialog__footer {
            text-align: center;
        }
        .sign-content {
            text-align: center;
            font-size: 16px;
            .sign-title {
                margin-bottom: 10px;
            }
        }
    }
    .blackboard-dialog {
        ::v-deep .el-dialog__body {
            height: calc( 100% - 60px);
            padding: 0 10px;
        }
    }
    .chapterNode-cascader-wrapper {
        position: relative;
        padding: 10px 10px 0;
        z-index: 9999;
        .el-input {
            ::v-deep .el-input__inner {
                background-color: transparent;
                color: #F5F7FA;
            }
        }
        .box {
            position: absolute;
            left: 10px;
            top: 50px;
            right: 10px;
            .chapterNode-cascaderBox {
                height: 200px;
                box-sizing: border-box;
                background-color: #fff;
                ::v-deep .el-cascader-menu__wrap {
                    height: 100%;
                    overflow-x: hidden;
                }
                ::v-deep .el-radio {
                    &.is-disabled {
                        & + .el-cascader-node__label {
                            color: #c0c4cc;
                        }
                        & + .el-cascader-node__label + .el-icon-arrow-right {
                            color: #c0c4cc;
                        }
                    }
                }
            }
        }
    }

</style>
