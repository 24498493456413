<template>
    <div class="main-box">
        <div class="main-content">
            <div class="subject-content">
                <div class="head-box">
                    <div class="title-box">
                        <div style="width: 4px;background: #409EFF;margin-right: 10px;"></div>
                        <div class="head-label">项目详情</div>
                    </div>
                    <div class="head-content">
                        <div class="img-box">
                            <img :src="projectInfo.project_cover" alt="">
                        </div>
                        <div class="right-content">
                            <div class="row1">
                                <div class="left-box">
                                    <div class="title-name">项目标题:</div>
                                    <div class="titile-detail">{{projectInfo.project_title}}</div>
                                </div>
                                <div class="right-box">
                                    <div class="time-name">发布时间:</div>
                                    <div class="time-detail">{{projectInfo.create_time}}</div>
                                </div>
                            </div>
                            <div class="row2">
                                <div class="left-box">
                                    <div class="title-name">项目简介:</div>
                                    <div class="titile-detail">{{projectInfo.project_content}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-content">
                    <div class="card">
                        <div class="first-show">
                            <div class='img-content'>
                                <img src="../../assets/images/project/project_target.png" alt="">
                            </div>
                            <div class='show-title'>项目目标</div>
                        </div>
                        <div class="card-content">
                            <div class="content-title">项目目标</div>
                            <div class="content-box">{{projectInfo.project_target}}</div>
                        </div>
                    </div>
                    <div class="card" style="margin-left: 30px;">
                        <div class="first-show">
                            <div class='img-content'>
                                <img src="../../assets/images/project/project_describe.png" alt="">
                            </div>
                            <div class='show-title'>项目描述</div>
                        </div>
                        <div class="card-content">
                            <div class="content-title">项目描述</div>
                            <div class="content-box">{{projectInfo.project_describe}}</div>
                        </div>
                    </div>
                    <div class="card" style="margin-left: 30px;">
                        <div class="first-show">
                            <div class='img-content'>
                                <img src="../../assets/images/project/project_analysis.png" alt="">
                            </div>
                            <div class='show-title'>项目分析</div>
                        </div>
                        <div class="card-content">
                            <div class="content-title">项目分析</div>
                            <div class="content-box">{{projectInfo.project_analysis}}</div>
                        </div>
                    </div>
                    <div class="card" style="margin-left: 30px;">
                        <div class="first-show">
                            <div class='img-content'>
                                <img src="../../assets/images/project/project_preper.png" alt="">
                            </div>
                            <div class='show-title'>项目准备</div>
                        </div>
                        <div class="card-content">
                            <div class="content-title">项目准备</div>
                            <div class="content-box">{{projectInfo.project_prepare}}</div>
                        </div>
                    </div>
                </div>
                <div class="task-box">
                    <div class="task-title">项目任务</div>
                    <div class="task-content">
                        <el-carousel indicator-position="outside" height="622px" :autoplay=false>
                            <el-carousel-item v-for="(item,index) in taskList" :key="index">
                                <div class="task-main">
                                    <div class="left-task">
                                        <div class="task-title" v-if="item.text.indexOf('<')!=-1"
                                             v-html="item.text"></div>
                                        <div class="task-title" v-else>{{item.text}}</div>
                                    </div>
                                    <div class="right-task">
                                        <div class="right-img">
                                            <img :src="item.image" alt="">
                                        </div>
                                    </div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
                <div class="step-box">
                    <div class="step-title">项目步骤</div>
                    <div class="table-box">
                        <el-table class="elTable" :data="projectInfo.project_step_score"
                                  :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa'}">
                            <el-table-column class="el-table-column" prop="index" label="序号" align="left"
                                             width="200">
                            </el-table-column>
                            <el-table-column prop="content" label="内容" align="left"></el-table-column>
                            <el-table-column prop="score" label="分值" align="right" width="200"></el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="ability-box">
                    <div class="ability-title">能力扩展</div>
                    <div class="ability-content">
                        <div class="ability-content-box" v-if="projectInfo.project_ability">
                            {{projectInfo.project_ability}}
                        </div>
                        <div class="ability-content-box" v-else>暂无</div>
                    </div>
                </div>
                <div class="knowledge-content">
                    <div class="knowledge-title">知识链接</div>
                    <div class="btn" @click="resourceVisible = true">
                        <i class="el-icon-plus" style="margin-right: 8px;"></i>添加资源
                    </div>
                    <div class="show-box">
                        <div class="knowledge-list-box">
                            <div class="knowledge-list-content"
                                 v-for="(item,index) in projectInfo.project_knowledge_link" :key="index">
                                <div style="z-index: 1;cursor: pointer" @click="handleClick(item)">
                                    <div class="circle-box" v-if="item.type == 1" style="background: #61BCEB">
                                        <i class="iconfont">&#xe733;</i>
                                    </div>
                                    <div class="circle-box" v-if="item.type == 2" style="background: #3DCAC8">
                                        <i class="iconfont">&#xeb62;</i>
                                    </div>
                                    <div class="circle-box" v-if="item.type == 3" style="background: #F0BC49">
                                        <i class="iconfont">&#xeb61;</i>
                                    </div>
                                    <div class="circle-box" v-if="item.type == 4" style="background: #EC75A4">
                                        <i class="iconfont">&#xeb63;</i>
                                    </div>
                                    <div class="circle-box" v-if="item.type == 5" style="background: #79ACDC">
                                        <i class="iconfont">&#xe625;</i>
                                    </div>
                                    <div class="circle-box" v-if="item.type == 6" style="background: #ffffff">
                                        <i class="iconfont">&#xe782;</i>
                                    </div>
                                    <div class="circle-box" v-if="item.type == 7" style="background: #5AB9E9">
                                        <i class="iconfont">&#xe715;</i>
                                    </div>
                                </div>
                                <div class="circle-bottom">{{item.name}}</div>
                                <div class="del-btn" @click="delKnowledge(item,index)">x</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sucai-box">
                    <div class="sucai-title">素材下载</div>
                    <div class="sucai-content">
                        <div class="sucai-content-box" v-for="(item,index) in projectInfo.project_sucai" :key="index">
                            <i class="iconfont">&#xe61a;</i>
                            <span>{{item.file_name}}</span>
                            <span style="margin-left: 12px;" @click="downloadSucai(item.file_download)"><i
                                    class="iconfont"
                                    style="color: #409EFF">&#xe725;</i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ResourceDialog v-if="resourceVisible === true" :resourceVisible='resourceVisible' @showDialog=handleDialog
                        @emitData="handleEmitData" />

        <!--查看应用详情弹窗-->
        <el-dialog title="欢迎使用yikeSoft" :visible.sync="dialogAppDetail" width="800px" center class="app-detail-dialog"
                   :close-on-click-modal="false">
            <div class="app-detail-content">
                <h1 class="title">欢迎使用yikeSoft</h1>
                <div class="content">
                    <span class="h2">{{appInfo.appTitle}}</span>
                    <span class="soft-introduction">软件功能介绍：</span>
                    <div class="soft-content">{{appInfo.appContent}}</div>
                </div>
            </div>
            <div slot="footer" class="info-dialog-footer">
                <a :href="appInfo.home_link" target="_blank" class="home">首页登录</a>
                <a :href="appInfo.shop_link" target="_blank" class="shop">商家登录</a>
                <a :href="appInfo.teacher_link" target="_blank" class="shop">教师登录</a>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import ResourceDialog from "../qualityCourse/component/ResourceDialog";

    export default {
        props: {
            projectId: {
                type: Number
            }
        },
        name: "MaterialProjectDetail",
        components: {
            ResourceDialog
        },
        created() {

        },
        data() {
            return {
                projectInfo: {},
                createTime: "",
                taskList: [],
                resourceVisible: false,
                //应用信息
                appInfo: {
                    appTitle: '',
                    appContent: '',
                    home_link: '',
                    shop_link: '',
                    teacher_link: '',
                },
                //是否显示查看应用详情弹窗
                dialogAppDetail: false,
            }
        },
        mounted() {
            this.getDetail();
        },
        watch: {
            projectId(val) {
                this.getDetail();
            }
        },
        methods: {
            getDetail() {
                let params = {
                    id: this.projectId,
                    type: 1
                }
                this.$shttp.axiosGetBy(this.$api.getProjectDetail, params, res => {
                    if (res.code === 200) {
                        this.projectInfo = res.data;
                        // this.timestampToTime(res.data.create_time);
                        this.getTaskList(res.data.project_task);
                        if (res.data.project_knowledge_link == null) {
                            this.projectInfo.project_knowledge_link = [];
                        }
                    }
                })
            },
            timestampToTime(timestamp) {
                let date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
                let Y = date.getFullYear() + '-';
                let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                let D = date.getDate() + ' ';
                this.createTime = Y + M + D;
            },
            getTaskList(val) {
                for (let i = 0; i < val.length; i++) {
                    this.taskList.push(val[i].project_task);
                    for (let j = 0; j < val[i].project_step.length; j++) {
                        this.taskList.push(val[i].project_step[j])
                    }
                }
            },
            downloadSucai(url) {
                window.open(url);
            },
            handleDialog(val) {
                this.resourceVisible = val;
            },
            handleEmitData(val) {
                let param = {
                    type: val.type,
                    project_id: Number(this.projectId)
                }
                if (val.type === 7) {
                    param.name = val.name;
                    param.url = val.url;
                } else if (val.type === 6) {
                    param.resource_id = val.resource_id
                } else {
                    param.resource_id = val.resource_id;
                    param.resource_table = val.resource_table
                }
                this.$shttp.axiosPost(this.$api.addProjectResource, param, res => {
                    if (res.code === 200) {
                        this.$message.success(res.msg);
                        this.getDetail();
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            delKnowledge(item, index) {
                this.$shttp.axiosGetBy(this.$api.delProjectResource, {resource_id: item.id}, res => {
                    if (res.code === 200) {
                        this.$message.success(res.msg);
                        this.getDetail();
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            handleClick(item) {
                if (item.type === 6) {
                    this.viewAppDetail(item);
                } else {
                    window.open(item.url, "_blank")
                }
            },
            //查看应用详情
            viewAppDetail(item) {
                this.dialogAppDetail = true;
                this.appInfo.appTitle = item.name;
                this.appInfo.appContent = item.intro;
                this.appInfo.home_link = item.home_link;
                this.appInfo.shop_link = item.shop_link;
                this.appInfo.teacher_link = item.teacher_link;
            },
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .el-scrollbar__view {
        padding-right: 20px;
        padding-left: 20px;
    }

    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }

    ::v-deep .el-carousel__indicators--outside {
        display: none;
    }

    .main-box {
        height: calc(100% - 120px);
        width: 93%;
        margin: 20px auto;
        background: #ffffff;

        .main-content {
            background: #ffffff;
            height: 100%;

            .subject-content {
                flex: 1;
                height: calc(100% - 63px);

                ::v-deep .el-scrollbar__view {
                    padding: 20px 100px 0 100px;
                }

                .head-box {
                    .title-box {
                        display: flex;
                    }

                    .head-content {
                        padding-top: 60px;
                        display: flex;

                        .img-box {
                            width: 200px;
                            height: 200px;

                            img {
                                max-height: 100%;
                                max-width: 100%;
                            }
                        }

                        .right-content {
                            margin-left: 40px;
                            overflow: hidden;
                            height: 200px;
                            width: calc(100% - 240px);

                            .row1 {
                                display: flex;
                                justify-content: space-between;

                                .left-box {
                                    display: flex;

                                    .title-name {
                                        font-weight: bold;
                                    }

                                    .titile-detail {
                                        margin-left: 10px;
                                    }
                                }

                                .right-box {
                                    display: flex;

                                    .time-detail {
                                        margin-left: 10px;
                                    }

                                    .titile-detail {
                                        margin-left: 10px;
                                    }
                                }
                            }

                            .row2 {
                                display: flex;
                                margin-top: 40px;

                                .left-box {
                                    display: flex;

                                    .title-name {
                                        font-weight: bold;
                                        min-width: 65px;
                                    }

                                    .titile-detail {
                                        margin-left: 10px;
                                        word-break: break-all;
                                        line-height: 1.5;
                                        max-height: 120px;
                                    }
                                }
                            }

                        }

                    }
                }

                .box-content {
                    display: flex;
                    margin-top: 100px;

                    .card {
                        width: calc(25% - 20px);
                        min-width: 250px;
                        height: 518px;
                        background: #FFFFFF;
                        box-shadow: 0px 1px 5px 0px rgba(32, 32, 32, 0.14);
                        border-radius: 10px;
                        position: relative;

                        .first-show {
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;

                            .show-title {
                                font-weight: bold;
                                margin-top: 85px;
                            }
                        }

                        .card-content {
                            width: 80%;
                            position: absolute;
                            top: 100px;
                            left: 0;
                            opacity: 0;
                            display: flex;
                            flex-direction: column;
                            text-align: center;
                            padding: 0 40px;

                            .content-box {
                                margin-top: 37px;
                                text-align: left;
                                word-break: break-all;
                                line-height: 1.5;
                                max-height: 300px;
                                overflow: hidden;
                            }
                        }
                    }

                    .card:before {
                        content: "";
                        width: 0;
                        height: 100%;
                        background: #fff;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        opacity: 0;
                    }

                    .card:hover:before {
                        width: 100%;
                        left: 0;
                        opacity: 0.5;
                    }

                    .card:hover {
                        box-shadow: 0px 5px 17px 1px rgba(32, 32, 32, 0.14);
                        border-radius: 10px;
                    }

                    .card:hover .card-content {
                        opacity: 1;
                    }

                    .card:hover .first-show {
                        opacity: 0;
                    }
                }

                .task-box {
                    margin-top: 60px;

                    .task-content {
                        width: 100%;
                        height: 622px;
                        border: 1px solid #DDDDDD;
                        border-radius: 10px;
                        margin-top: 20px;

                        .task-main {
                            display: flex;
                            height: 100%;

                            .left-task {
                                width: 50%;
                                padding: 76px 68px 40px 38px;
                                word-break: break-all;
                                line-height: 1.5;
                                overflow: hidden;
                                height: 80%;
                            }

                            .right-task {
                                width: 50%;
                                height: 100%;

                                .right-img {
                                    padding: 40px 40px 40px 0;
                                    width: 95%;
                                    height: 88%;

                                    img {
                                        max-height: 100%;
                                        max-width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }

                .step-box {
                    margin-top: 60px;

                    .step-title {
                        font-weight: bold;
                    }

                    .table-box {
                        border: 1px solid #DDDDDD;
                        margin-top: 20px;
                    }
                }

                .ability-box {
                    margin-top: 60px;

                    .ability-title {
                        font-weight: bold;
                    }

                    .ability-content {
                        margin-top: 20px;
                        min-height: 100px;
                        background: #F5F7FA;
                        border-radius: 10px;

                        .ability-content-box {
                            padding: 20px;
                            word-break: break-all;
                            line-height: 1.5;
                        }
                    }
                }

                .knowledge-content {
                    display: flex;
                    flex-direction: column;
                    margin-top: 60px;

                    .knowledge-title {
                        font-weight: bold;
                    }

                    .btn {
                        margin-top: 20px;
                        width: 100px;
                        height: 30px;
                        background: #ECF5FF;
                        border: 1px solid #DCECFE;
                        border-radius: 4px;
                        color: #409EFF;
                        text-align: center;
                        line-height: 2;
                        cursor: pointer;
                    }

                    .show-box {
                        min-height: 100px;
                        background: #F5F7FA;
                        border-radius: 10px;
                        margin-top: 10px;
                        display: flex;

                        .knowledge-list-box {
                            width: 100%;
                            padding-top: 10px;
                            display: flex;
                            flex-wrap: wrap;

                            .knowledge-list-content {
                                position: relative;
                                width: calc(10% - 30px);
                                min-width: 100px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                padding: 0 20px;

                                .circle-box {
                                    width: 60px;
                                    height: 60px;
                                    border-radius: 50%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    color: #ffffff;

                                    ::v-deep .iconfont {
                                        font-size: 26px;
                                    }
                                }

                                .circle-bottom {
                                    height: 30px;
                                    overflow: hidden;
                                    margin-top: 10px;
                                    max-width: 100%;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }

                                .del-btn {
                                    width: 18px;
                                    height: 18px;
                                    background: #FF0000;
                                    color: #ffffff;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border-radius: 50%;
                                    position: absolute;
                                    right: 0;
                                    top: 20px;
                                    opacity: 0;
                                    cursor: pointer;
                                }
                            }

                            .knowledge-list-content:before {
                                content: "";
                                width: 0;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 50%;
                                opacity: 0;
                            }


                            .knowledge-list-content:hover:before {
                                width: 100%;
                                left: 0;
                                opacity: 0.5;
                            }

                            .knowledge-list-content:hover .del-btn {
                                opacity: 1;
                            }
                        }
                    }
                }

                .sucai-box {
                    margin-top: 60px;

                    .btn {
                        margin-top: 20px;
                        width: 100px;
                        height: 30px;
                        background: #ECF5FF;
                        border: 1px solid #DCECFE;
                        border-radius: 4px;
                        color: #409EFF;
                        text-align: center;
                        line-height: 2;
                        cursor: pointer;
                    }

                    .sucai-title {
                        font-weight: bold;
                    }

                    .sucai-content {
                        margin-top: 20px;
                        min-height: 70px;
                        background: #F5F7FA;
                        border-radius: 10px;
                        display: flex;

                        .sucai-content-box {
                            padding: 20px;
                            word-break: break-all;
                            line-height: 1.5;
                        }
                    }
                }
            }
        }

        .app-detail-dialog {
            ::v-deep .el-dialog {
                ::v-deep .el-dialog__header {
                    border-bottom: 1px solid #e6e7ea;
                }

                .title {
                    text-align: center;
                    margin: 0;
                }

                .content {
                    display: flex;
                    flex-direction: column;

                    .h2 {
                        font-size: 18px;
                        font-weight: 500;
                        text-align: center;
                        margin: 10px 0;
                    }

                    .soft-introduction {
                        text-align: center;
                    }

                    .soft-content {
                        padding: 10px 50px;
                        min-height: 200px;
                    }
                }

                ::v-deep .el-dialog__footer {
                    border-top: 1px solid #e6e7ea;
                    padding: 20px;

                    a {
                        padding: 7px 20px;
                        font-size: 14px;
                        border-radius: 4px;
                        margin-right: 20px;
                    }

                    a.home {
                        border: 1px solid #DCDFE6;
                        color: #606266;

                        &:hover {
                            color: #409EFF;
                            border-color: #c6e2ff;
                            background-color: #ecf5ff;
                        }
                    }

                    a.shop {
                        color: #FFF;
                        background-color: #409EFF;
                        border-color: #409EFF;

                        &:hover {
                            background: #66b1ff;
                            border-color: #66b1ff;
                            color: #FFF;
                        }
                    }
                }
            }
        }
    }
</style>