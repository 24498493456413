<template>
    <div class="quiz-wrapper">
        <el-scrollbar class="quiz-content" :native="false" ref="scrollArea">
            <el-timeline v-if="role === 3">
                <div v-if="quizList.length === 0" style="text-align: center;background: #fff; color: #666; margin: 20px 0; padding: 10px">暂无小测</div>
                <el-timeline-item :timestamp="quizKey" placement="top" class="quiz-item" v-for="(quizItem, quizKey) in quizList" color="#E10303">
                    <el-card v-for="teacherItem in quizItem">
                        <div class="item-info">
                            <div class="user-avatar">
                                <img v-if="teacherItem.t_ico" :src="teacherItem.t_ico" alt="">
                                <img v-else src="../../assets/images/user_img.png" alt="" />
                            </div>
                            <span class="user-name text-overflow">{{teacherItem.t_name}}</span>
                            <el-button type="primary" size="mini" @click="toPaperDetail(teacherItem.id)">进入小测</el-button>
                            <el-button type="danger" size="mini" @click="delPaper(teacherItem.id)" v-if="role === 3">删除</el-button>
                        </div>
                        <div class="item-bottom">
                            <span class="item-title text-overflow">{{teacherItem.paper_name}}</span>
                            <span class="item-time">{{teacherItem.create_time2}}</span>
                        </div>
                    </el-card>
                </el-timeline-item>
            </el-timeline>
            <el-timeline v-if="role === 4">
                <div v-if="studentQuizList.length === 0" style="text-align: center;background: #fff; color: #666; margin: 20px 0; padding: 10px">暂无小测</div>
                <el-timeline-item :timestamp="quizKey" placement="top" class="quiz-item" v-for="(quizItem, quizKey) in studentQuizList" color="#E10303">
                    <el-card v-for="teacherItem in quizItem">
                        <div class="item-info">
                            <div class="user-avatar">
                                <img v-if="teacherItem.t_ico" :src="teacherItem.t_ico" alt="">
                                <img v-else src="../../assets/images/user_img.png" alt="" />
                            </div>
                            <span class="user-name text-overflow">{{teacherItem.t_name}}</span>
                            <el-button type="primary" size="mini" @click="toStudentPaperDetail(teacherItem)">进入小测</el-button>
                        </div>
                        <div class="item-bottom">
                            <span class="item-title text-overflow">{{teacherItem.paper_name}}</span>
                            <div v-if="role === 4" class="item-status">
                                <span class="red" v-if="teacherItem.is_submit === 0">未提交</span>
                                <span v-if="teacherItem.is_submit === 1 && teacherItem.is_read === 1">未评分</span>
                                <span class="blue" v-if="teacherItem.is_submit === 1 && teacherItem.is_read === 0 && teacherItem.score !== undefined">{{teacherItem.score}}分</span>
                            </div>
                            <span class="item-time">{{teacherItem.issue_time2}}</span>
                        </div>
                    </el-card>
                </el-timeline-item>
            </el-timeline>
        </el-scrollbar>
        <div class="choose-paper" v-if="role === 3">
            <el-button type="primary" size="medium" @click="choosePaper">发布小测</el-button>
        </div>
        <el-dialog title="选择试卷" :visible.sync="choosePaperDialog" width="30%" @close="resetForm()" :close-on-click-modal="false" :append-to-body="true">
            <el-form :model="choosePaperForm" ref="choosePaperForm" :rules="paperRules" label-position="left">
                <el-form-item label="试卷选择" :label-width="formLabelWidth" prop="paperId">
                    <el-select v-model="choosePaperForm.paperId" placeholder="请选择试卷" clearable style="width: 100%;">
                        <el-option v-for="paperItem in paperList" :label="paperItem.name" :value="paperItem.resource_id"></el-option>
                    </el-select>
                </el-form-item>
              <el-form-item label="小测时长:" :label-width="formLabelWidth" prop="paper_time">
                <el-input-number
                    :precision="0"
                    v-model="choosePaperForm.paper_time"
                    :controls="false"
                    class="pw-input"
                    :min="1"
                    :max="99999999"
                    style="width: 175px;">
                </el-input-number>
                <span style="margin-left: 5px">分钟</span>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="choosePaperDialog = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="addPaper('choosePaperForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import wsc from "../../utils/wsConfig";
    import _ from "underscore";
    export default {
        props: {
            chapterNodeCurrent: {
                type: Array
            }
            // chapterList: {
            //     type: Array,
            // }
        },
        data() {
            return {
                id: Number(this.$route.query.id) || '',
                recordId: Number(this.$route.query.recordId) || '',
                classId: Number(this.$route.query.classId) || '',
                role: Number(localStorage.getItem('role')),

                quizList: [],
                studentQuizList: [],
                papersChoose: '',
                formLabelWidth: '82px',

                choosePaperDialog: false,
                choosePaperForm: {
                    id: '',
                    paperId: '',
                    paper_time:'',
                },
                paperList: [],
                paperRules: {
                    paperId: [
                        { required: true, message: '请选择试卷', trigger: 'change' }
                    ],
                    paper_time:[
                      { required: true, message: '请输入实训时长', trigger: 'blur' },
                    ]
                },
            }
        },
        mounted() {
            this.getQuizList();
        },
        methods: {
            test(data) {
                let _key = _.keys(data);
                let _value = _.values(data);
                let _keys = _.keys(this.studentQuizList);
                if (_keys.indexOf(_key[0]) !== -1) {
                    let tmpArr = this.studentQuizList[_key[0]];
                    tmpArr.push(_value[0]);
                    this.$set(this.studentQuizList, _key[0], tmpArr);
                } else {
                    this.$set(this.studentQuizList, _key[0], [_value[0]]);
                }
            },
            getQuizList() {
                if (this.role === 3) {
                    let params = {
                        c_id: this.classId,
                        course_id: this.id,
                        type: 'all',
                    }
                    this.$shttp.axiosGetBy(this.$api.getQuizList, params, (res) => {
                        if (res.code === 200) {
                            this.quizList = res.data
                            // this.$nextTick(function () {
                            //     this.$refs['scrollArea'].wrap.scrollTop = 9999999;
                            // })
                        }
                    }, (err) => {
                        console.log(err)
                    })
                } else if (this.role === 4) {
                    let params = {
                        course_id: this.id,
                        type: 'all',
                    }
                    this.$shttp.axiosGetBy(this.$api.seeStudentQuizList, params, (res) => {
                        if (res.code === 200) {
                            this.studentQuizList = res.data
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }
            },
            choosePaper() {
                this.choosePaperDialog = true;
                this.getLessonQuizList();
            },
            //教师端 查询老师备课的小测
            getLessonQuizList() {
                let param = {
                    course_id: String(this.id),
                    course_chapter: String(this.chapterNodeCurrent[0]),
                    course_node: String(this.chapterNodeCurrent[1]),
                }
                //先查询课程那边自定义/拖拽保存的小测列表
                this.$shttp.axiosGetBy(this.$api.course_getcoursecustom, param, (res) => {
                    if (res.code === 200) {
                        //没有数据的话就查询总管理员创建课程时拖拽保存的小测列表
                        if (res.data.length === 0) {
                            this.getPaperList()
                        } else {
                            if (res.data.exams) {
                                let arrList = res.data.exams;
                                let paper_list = [];
                                arrList.forEach(item => {
                                    let obj = {
                                        name: item.paper_name,
                                        resource_id: item.id,
                                    }
                                    paper_list.push(obj);
                                })
                                this.paperList = paper_list;
                            } else {
                                this.paperList = [];
                            }
                        }
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            getPaperList() {
                let params = {
                    id: this.id,
                }
                this.$shttp.axiosGetBy(this.$api.exmapapers, params, (res) => {
                    if (res.code === 200) {
                        this.paperList = res.list
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            addPaper(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let params = {
                            paper_id: this.choosePaperForm.paperId,
                            course_id: this.id,
                            c_id: this.classId,
                            course_record_id: this.recordId,
                            quiz_minute:this.choosePaperForm.paper_time
                        }
                        this.$shttp.axiosPost(this.$api.teacherIssueQuiz, params, (res) => {
                            if (res.code === 200) {
                                this.$emit('sendMsg', true);
                                this.$message.success(res.msg);
                                this.getQuizList();
                            } else {
                                this.$message.warning(res.msg)
                            }
                        }, (err) => {
                            console.log(err)
                        })
                        this.choosePaperDialog = false;
                        this.resetForm()
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm() {
                this.choosePaperForm = {
                    id: '',
                    paperId: '',
                }
            },
            toPaperDetail(id) {
                let routeUrl = this.$router.resolve({
                    path: '/course/PaperDetail',
                    query: {id: id}
                })
                // this.$router.push({path: '/course/PaperDetail', query: {id: id}})
                window.open(routeUrl .href, '_blank')
            },
            //删除小测
            delPaper(id) {
                this.$confirm('是否删除该小测，删除小测同时将删除学生提交的小测记录', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$shttp.axiosGetBy(this.$api.jd_delQuiz, {id: String(id)}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getQuizList();
                            this.$emit('sendMsg', true);
                        } else {
                            this.$message.error(res.msg);
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            toStudentPaperDetail(item) {
                let routeUrl = this.$router.resolve({
                    path: '/course/PaperDetail',
                    query: {
                        id: item.quiz_id,
                        isSubmit: item.is_submit
                    }
                })
                // this.$router.push({path: '/course/PaperDetail', query: {id: id}})
                window.open(routeUrl .href, '_blank')
            },
        }
    }
</script>

<style scoped lang="scss">
    .quiz-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        .quiz-content {
            height: 100%;
            flex: 1;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .el-timeline {
                padding: 20px;
                .quiz-item {
                    &:last-child {
                        padding-bottom: 0;
                    }
                    ::v-deep .el-timeline-item__tail {
                        border-color: #E10303;
                    }
                    .el-card {
                        margin-bottom: 16px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        ::v-deep .el-card__body {
                            padding: 16px;
                        }
                    }
                    .item-info {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .user-avatar {
                            width: 24px;
                            height: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            border-radius: 50%;
                            border: 1px solid #ddd;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        .user-name {
                            width: 1%;
                            flex: 1;
                            margin: 0 10px;
                        }
                    }
                    .item-bottom {
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .item-title {
                            width: 1%;
                            flex: 1;
                        }
                        .item-time {
                            color: #999;
                        }
                        .item-status {
                            margin: 0 10px;
                            .red {
                                color: #ff0000;
                            }
                            .blue {
                                color: #409eff;
                            }
                        }
                    }
                }
            }
        }
        .choose-paper {
            padding: 10px 20px;
            border-top: 1px solid #ddd;
            background: #fff;
            text-align: center;
        }
    }
</style>
