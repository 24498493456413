<template>
    <div class="tool-content">
        <div class="left-tool">
            <div class="tool-item" @click="viewRecording">
                <i class="iconfont">&#xe670;</i>
                <span class="tool-text">录音</span>
            </div>
            <div class="tool-item" @click="viewLink">
                <i class="iconfont">&#xe66e;</i>
                <span class="tool-text">链接</span>
            </div>
            <div class="tool-item" @click="viewResources">
                <i class="iconfont">&#xe66f;</i>
                <span class="tool-text">配套材料</span>
            </div>
        </div>
        <!--查看工具弹窗-->
        <el-dialog :visible.sync="viewToolDialog" width="1000px" :close-on-click-modal="false" class="add-tool-dialog"
                   append-to-body @close="handleCloseTool">
            <div class="dialog-header" slot="title">
                <span class="left">{{dialog_view_title}}</span>
                <span class="center" v-if="dialog_view_title === '配套材料'">{{course_name}}第{{zhangIndex+1}}章第{{jieIndex+1}}节</span>
            </div>
            <div class="dialog-content">
                <div class="content-item view-recording-item" v-if="dialog_view_title === '录音'">
                    <div class="select type-list">
                        <span class="select-text">音乐：</span>
                        <el-select v-model="recordingValve" placeholder="请选择" style="width: 300px" clearable
                                   @change="selectRecording">
                            <el-option
                                    v-for="item in recordingList"
                                    :key="item.id"
                                    :label="item.data.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="content" v-if="recordingUrl !==''">
                        <audio controls>
                            <source :src="recordingUrl" type="audio/ogg">
                        </audio>
                    </div>
                    <div class="content" v-if="recordingUrl ===''">
                        <div class="no-data">
                            <span>暂无数据</span>
                        </div>
                    </div>
                </div>
                <div class="content-item view-link-item" v-if="dialog_view_title === '链接'">
                    <div class="select type-list">
                        <span class="select-text">名称：</span>
                        <el-select v-model="linkValve" placeholder="请选择" style="width: 300px" clearable
                                   @change="selectLink">
                            <el-option
                                    v-for="item in linkList"
                                    :key="item.id"
                                    :label="item.data.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="content" v-if="linkUrl !==''">
                        <a :href="linkUrl" target="_blank">{{linkUrl}}</a>
                    </div>
                    <div class="content" v-if="linkUrl ===''">
                        <div class="no-data">
                            <span>暂无数据</span>
                        </div>
                    </div>
                </div>
                <div class="content-item resources-item" v-if="dialog_view_title === '配套材料'">
                    <div class="select type-list">
                        <span class="select-text">类型：</span>
                        <el-select v-model="typeValve" placeholder="请选择" style="width: 300px" clearable
                                   @change="selectShowType">
                            <el-option
                                    v-for="item in typeList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="content">
                        <el-scrollbar class="left-list" :native="false">
                            <div class="type-item"
                                 v-for="item in addedMaterialLists"
                                 :class="{current: currentItem === item.data.material_id}"
                                 :title="item.data.title"
                                 @click="selectTypeMaterial(item)">
                                {{item.data.title}}
                            </div>
                            <div v-if="addedMaterialLists.length === 0" class="no-data">
                                <span>暂无数据</span>
                            </div>
                        </el-scrollbar>
                        <div class="right-list">
                            <div v-if="is_no_data" class="no-data">
                                <span>暂无数据</span>
                            </div>
                            <div v-if="suffix=='avi'" style="height:90%">
                                <video v-if="addedMaterialLists.length > 0" :src="added_material_content" width="100%" height="100%"
                                    class="iframe-content" autoplay controls></video>
                            </div>
                            <div style="height:100%" v-else>
                                <iframe v-if="addedMaterialLists.length > 0" :src="added_material_content" width="100%" height="100%"
                                    class="iframe-content"></iframe>
                            </div>
                        </div>
                        <!--<div class="right-list">-->
                        <!--<video :src="require('../../assets/resources/home.mp4')" controls="controls" width="100%"-->
                        <!--height="100%"></video>-->
                        <!--</div>-->
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "WareToolInClass",
        props: ['resource_id','course_name','zhangIndex','jieIndex'],
        data() {
            return {
                course_id: '',
                //弹窗位置
                pop_up_position: 'bottom-start',
                //是否显示添加弹窗
                addToolDialog: false,
                //选中的类型
                typeValve: 999,
                //类型类表
                typeList: [],
                typeChildLists: [],
                material_content: '',
                currentItem: '1',
                //已添加的配套材料列表
                addedMaterialLists: [],
                added_material_content: '',
                recordingFile: '',
                //是否显示查看
                viewToolDialog: false,
                //查看工具弹窗标题
                dialog_view_title: '',
                //选中的录音
                recordingValve: '',
                recordingUrl: '',
                //录音列表
                recordingList: [],
                //选中的链接
                linkValve: '',
                linkUrl: '',
                //链接列表
                linkList: [],
                videoName: '',
                videoPath: [],
                //配套材料-暂无数据
                is_no_data: false,
                suffix:''
            }
        },
        mounted() {
            this.course_id = this.$route.query.course_id;
            this.getTypeList();
        },
        methods: {
            getTypeList() {
                this.$thttp.axiosGet(this.$api.meterial_mtlTypeList, (res) => {
                    if (res.code === 200) {
                        this.typeList = res.data;
                        let obj = {
                            id: 999,
                            name: '全部',
                            type: 0
                        }
                        this.typeList.splice(0, 0, obj);
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //选中的类型
            selectType(val) {
                this.typeValve = val;
                this.getMaterialList();
            },
            selectTypeChild(item) {
                this.currentItem = item.id;
                this.getMaterialContent(item.id);
            },
            selectShowType(val) {
                if (val === 999) {
                    this.getAllCourseWare();
                } else {
                    this.getCourseWare(3);
                }
            },
            selectTypeMaterial(item) {
                this.currentItem = item.data.material_id;
                this.getAddedMaterialContent(item.data.material_id);
            },
            //重置弹窗
            resetAddToolForm() {
                this.addToolDialog = false;
            },
            //查看工具弹窗
            viewRecording() {
                this.viewToolDialog = true;
                this.dialog_view_title = '录音';
                this.getCourseWare(1);
            },
            viewLink() {
                this.viewToolDialog = true;
                this.dialog_view_title = '链接';
                this.getCourseWare(2);
            },
            viewResources() {
                this.viewToolDialog = true;
                this.dialog_view_title = '配套材料';
                if (this.typeValve === 999) {
                    this.getAllCourseWare();
                } else {
                    this.getCourseWare(3);
                }
            },
            getCourseWare(type) {
                let param = {
                    resource_id: this.resource_id,
                    type: type,
                    show: 'all',
                }
                if (type === 3) {
                    param.material_type_id = this.typeValve;
                }
                this.$thttp.axiosGetBy(this.$api.courseware_showCourseWare, param, (res) => {
                    if (res.code === 200) {
                        if (type === 1) {
                            this.recordingList = res.data;
                            if (res.data.length > 0) {
                                this.recordingValve = res.data[0].id;
                                this.recordingUrl = res.data[0].data.url.url;
                            }
                        } else if (type === 2) {
                            this.linkList = res.data;
                            if (res.data.length > 0) {
                                this.linkValve = res.data[0].id;
                                this.linkUrl = res.data[0].data.url;
                            }
                        } else {
                            this.addedMaterialLists = res.data;
                            if (res.data.length > 0) {
                                this.is_no_data = false;
                                this.currentItem = res.data[0].data.material_id;
                                this.getAddedMaterialContent(res.data[0].data.material_id);
                            } else {
                                this.is_no_data = true;
                            }
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //获取所有配套材料
            getAllCourseWare() {
                let param = {
                    resource_id: this.resource_id,
                    type: 3,
                    show: 'all',
                }
                this.$thttp.axiosGetBy(this.$api.courseware_showCourseWare, param, (res) => {
                    if (res.code === 200) {
                        this.addedMaterialLists = res.data;
                        if (res.data.length > 0) {
                            this.is_no_data = false;
                            this.currentItem = res.data[0].data.material_id;
                            this.getAddedMaterialContent(res.data[0].data.material_id);
                        } else {
                            this.is_no_data = true;
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            handleCloseTool() {
                this.recordingList = [];
                this.linkList = [];
                this.recordingUrl = '';
                this.linkUrl = '';
                this.recordingValve = '';
                this.linkValve = '';
                this.typeValve = 999;
            },
            //选中的录音
            selectRecording(val) {
                this.recordingList.forEach(item => {
                    if(item.id === val){
                        this.recordingUrl = item.data.url.url;
                    }
                })
                if(val === ''){
                    this.recordingUrl = ''
                }
            },
            //选中的链接
            selectLink(val) {
                this.linkList.forEach(item => {
                    if(item.id === val){
                        this.linkUrl = item.data.url;
                    }
                })
                if(val === ''){
                    this.linkUrl = ''
                }
            },
            //获取配套材料列表
            getMaterialList() {
                let param = {
                    course_id: this.$route.query.course_id,
                    course_chapter: this.zhangIndex,
                    course_node: this.jieIndex,
                    type: 'all'
                }
                if (this.typeValve !== 999) {
                    param.meterial_type_id = this.typeValve;
                }
                this.$thttp.axiosGetBy(this.$api.meterial_showMeterialList, param, (res) => {
                    if (res.code === 200) {
                        this.typeChildLists = res.data;
                        if (res.data.length > 0) {
                            this.is_no_data = false;
                            this.currentItem = res.data[0].id;
                            this.getMaterialContent(res.data[0].id);
                        } else {
                            this.is_no_data = true;
                        }
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //获取配套材料对应资源
            getMaterialContent(id) {
                this.typeChildLists.forEach((item) => {
                    if (item.id === id) {
                        this.material_content = item.file_path.url;
                    }
                })
            },
            //获取已添加对的配套材料对应资源
            getAddedMaterialContent(id) {
                this.addedMaterialLists.forEach((item) => {
                    if (item.data.material_id === id) {
                        this.added_material_content = item.data.file_path.url;
                    }
                })
                this.suffix=this.added_material_content.substring(this.added_material_content.lastIndexOf('.')+1)
            }
        }
    }
</script>

<style scoped lang="scss">
    .left-tool {
        position: absolute;
        z-index: 999;
        width: 80px;
        /*height: calc(100% - 150px);*/
        height: calc(100% - 92px);
        background-color: #333;
        right: 13px;
        top: 92px;
        padding-top: 80px;
        box-sizing: border-box;
        .tool-item {
            color: #fff;
            font-size: 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            cursor: pointer;
            i.iconfont {
                margin-bottom: 10px;
            }
            &:nth-child(2) i.iconfont {
                font-size: 14px;
            }
            &:nth-child(3) i.iconfont {
                font-size: 18px;
            }
        }
    }
    .add-tool-dialog {
        ::v-deep .el-dialog {
            border-radius: 6px;
        }
        ::v-deep .el-dialog__header {
            padding: 15px 20px;
            color: #fff;
            font-size: 16px;
            background-color: #409EFF;
            border-radius: 6px 6px 0 0;
            .dialog-header {
                display: flex;
                position: relative;
                .center {
                    position: absolute;
                    display: inline-block;
                    text-align: center;
                    width: 100%;
                }
            }
            ::v-deep .el-dialog__headerbtn .el-dialog__close {
                color: #fff;
            }
        }
        ::v-deep .el-dialog__body {
            /*height: 467px;*/
            padding: 0;
            .dialog-content {
                height: 100%;
                background-color: #F2F2F2;
                .content-item {
                    display: flex;
                    flex-direction: column;
                }
                .resources-item {
                    /*height: 467px;*/
                    height: 667px;
                }
                .recording-item {
                    background-color: #fff;
                    height: 267px;
                }
                .view-recording-item {
                    height: 447px;
                    .content {
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        margin-top: 13px;
                        background-color: #fff;
                        .recording-name {
                            height: 60px;
                        }
                    }
                }
                .view-link-item {
                    background-color: #fff;
                    height: 320px;
                    .content {
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-color: #F2F2F2;
                        margin: 20px;
                    }
                }
                .type-list {
                    display: flex;
                    align-items: center;
                    height: 80px;
                    background-color: #fff;
                    padding: 0 20px;
                }
                .content {
                    display: flex;
                    flex: 1;
                    height: 1%;
                    padding: 13px;
                    .left-list {
                        width: 200px;
                        height: 100%;
                        background-color: #fff;
                        ::v-deep .el-scrollbar__wrap {
                            overflow-x: hidden;
                            .el-scrollbar__view {
                                height: 100%;
                            }
                        }
                        .type-item {
                            margin: 10px;
                            height: 29px;
                            line-height: 29px;
                            padding-left: 10px;
                            color: #333;
                            font-size: 14px;
                            cursor: pointer;
                            width: calc(100% - 30px);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            &:hover {
                                background-color: #ECF5FF;
                                color: #409EFF;
                            }
                        }
                        .current {
                            background-color: #ECF5FF;
                            color: #409EFF;
                        }
                    }
                    .right-list {
                        flex: 1;
                        width: 1%;
                        margin: 22px 22px 22px 35px;
                        box-sizing: border-box;
                        /*background-color: rebeccapurple;*/
                    }
                }
            }
        }
    }
    .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
</style>